<template>
  <div class="recurring-orders-wrapper" v-if="init">
    <h1>Subscriptions</h1>

    <RecurringOrderEditor v-if="eId !== null" />

    <div v-else class="recurring-orders-list">
      <button @click="$store.commit('recurringOrders/setEId', 'create')">
        Create new
      </button>
      <div
        class="recurring-order"
        v-for="order in recurringOrders"
        :key="order.id"
        @click="startEditing(order)"
      >
        <div
          class="recurring-order-status"
          :class="{ active: order.active }"
        ></div>
        <div class="recurring-order-header">
          <span class="name">{{ order.name }}</span>
          <span class="company">{{
            getCompanyNameByVismaId(order.companyVismaId)
          }}</span>
        </div>
        <div class="recurring-order-meta">
          <span class="order-lines"
            >{{ order.orderLines.length }}
            {{ order.orderLines.length > 0 ? "products" : "product" }}</span
          >
          <span class="frequency">
            {{ getDayLabels(order.days) }}
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";

export default {
  name: "RecurringOrders",
  data() {
    return {
      dayLabels: ["mon", "tue", "wed", "thu", "fri", "sat", "sun"],
    };
  },
  computed: {
    ...mapState({
      init: (state) => state.recurringOrders.init,
      recurringOrders: (state) => state.recurringOrders.recurringOrders,
      eId: (state) => state.recurringOrders.eId,
      eName: (state) => state.recurringOrders.eName,
      eNote: (state) => state.recurringOrders.eNote,
    }),
    ...mapGetters("settings", ["getCompanyNameByVismaId"]),
    getDayLabels() {
      return (days) => {
        const sortedDays = [...days].sort();
        return sortedDays.map((day) => this.dayLabels[day]).join(", ");
      };
    },
  },
  mounted() {},
  async created() {
    if (!this.init) {
      await this.$store.dispatch("recurringOrders/bindRecurringOrders");
      this.$store.commit("recurringOrders/setInit", true);
      console.log(this.recurringOrders);
    }
  },
  beforeDestroy() {
    this.$store.dispatch("recurringOrders/unbindRecurringOrders");
    this.$store.commit("recurringOrders/setInit", false);
  },
  methods: {
    // getDayLabels(days) {
    //   days.sort();
    //   return days.map((day) => this.dayLabels[day]).join(", ");
    // },
    startEditing(order) {
      this.$store.dispatch("recurringOrders/setupEditing", order);
    },
  },
};
</script>
